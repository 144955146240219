<!--
 * @Description:西安市地图
 -->
<template>
	<div class="hei">
		<div id="xianmap" ref="map_charts" style="width: 80vw;height: 80vh;margin-top: 10vh;"></div>
	</div>
</template>

<script>
import * as createcharts from "echarts";
import mapJson from './xianmap.json'
var countryData = [{
	name: "新城区",
	projectNum: 12,
	projectProgress: 30,
	fundsProgress: 25,
	heatRate: 80,
	pmLevel: 20,
	itemStyle: {
		areaColor: "#9AF8EC"
	},
	emphasis: {
		itemStyle: {
			areaColor: "#9AF8EC",
			borderColor: "#9AF8EC",
			borderWidth: 10
		}
	}
},
{
	name: "碑林区",
	projectNum: 12,
	projectProgress: 30,
	fundsProgress: 25,
	heatRate: 80,
	pmLevel: 20,
	itemStyle: {
		areaColor: "#F598B9"
	},
	emphasis: {
		itemStyle: {
			areaColor: "#F598B9",
			borderColor: "#F598B9",
			borderWidth: 10
		}
	}
},
{
	name: "莲湖区",
	projectNum: 12,
	projectProgress: 30,
	fundsProgress: 25,
	heatRate: 80,
	pmLevel: 20,
	itemStyle: {
		areaColor: "#F6E4A3"
	},
	emphasis: {
		itemStyle: {
			areaColor: "#F6E4A3",
			borderColor: "#F6E4A3",
			borderWidth: 10
		}
	}
},
{
	name: "灞桥区",
	projectNum: 12,
	projectProgress: 30,
	fundsProgress: 25,
	heatRate: 80,
	pmLevel: 20,
	itemStyle: {
		areaColor: "#F498B9"
	},
	emphasis: {
		itemStyle: {
			areaColor: "#F498B9",
			borderColor: "#F498B9",
			borderWidth: 10
		}
	}
},
{
	name: "未央区",
	projectNum: 12,
	projectProgress: 30,
	fundsProgress: 25,
	heatRate: 80,
	pmLevel: 20,
	itemStyle: {
		areaColor: "#CCB3F6"
	},
	emphasis: {
		itemStyle: {
			areaColor: "#CCB3F6",
			borderColor: "#CCB3F6",
			borderWidth: 10
		}
	}
},
{
	name: "雁塔区",
	value: 8,
	projectNum: 12,
	projectProgress: 30,
	fundsProgress: 25,
	heatRate: 80,
	pmLevel: 20,
	itemStyle: {
		areaColor: "#46ABEF"
	},
	emphasis: {
		itemStyle: {
			areaColor: "#46ABEF",
			borderColor: "#46ABEF",
			borderWidth: 10
		}
	}
},
{
	name: "阎良区",
	projectNum: 12,
	projectProgress: 30,
	fundsProgress: 25,
	heatRate: 80,
	pmLevel: 20,
	itemStyle: {
		areaColor: "#F598B9"
	},
	emphasis: {
		itemStyle: {
			areaColor: "#F598B9",
			borderColor: "#F598B9",
			borderWidth: 10
		}
	}
},
{
	name: "临潼区",
	projectNum: 12,
	projectProgress: 30,
	fundsProgress: 25,
	heatRate: 80,
	pmLevel: 20,
	itemStyle: {
		areaColor: "#53ECAC"
	},
	emphasis: {
		itemStyle: {
			areaColor: "#53ECAC",
			borderColor: "#53ECAC",
			borderWidth: 10
		}
	}
},
{
	name: "高陵区",
	projectNum: 12,
	projectProgress: 30,
	fundsProgress: 25,
	heatRate: 80,
	pmLevel: 20,
	itemStyle: {
		areaColor: "#F6E4A3"
	},
	emphasis: {
		itemStyle: {
			areaColor: "#F6E4A3",
			borderColor: "#F6E4A3",
			borderWidth: 10
		}
	}
},
{
	name: "长安区",
	projectNum: 12,
	projectProgress: 30,
	fundsProgress: 25,
	heatRate: 80,
	pmLevel: 20,
	itemStyle: {
		areaColor: "#90F4E8",
		shadowColor: "#90F4E888",
		shadowBlur: 5,
		shadowOffsetX: 0,
		shadowOffsetY: 10
	},
	emphasis: {
		itemStyle: {
			areaColor: "#90F4E8",
			borderColor: "#90F4E8",
			borderWidth: 10
		}
	}
},
{
	name: "蓝田县",
	projectNum: 12,
	projectProgress: 30,
	fundsProgress: 25,
	heatRate: 80,
	pmLevel: 20,
	itemStyle: {
		areaColor: "#D2BAF8",
		shadowColor: "#D2BAF888",
		shadowBlur: 5,
		shadowOffsetX: 0,
		shadowOffsetY: 10
	},
	emphasis: {
		itemStyle: {
			areaColor: "#D2BAF8",
			borderColor: "#D2BAF8",
			borderWidth: 10
		}
	}
},
{
	name: "鄠邑区",
	projectNum: 12,
	projectProgress: 30,
	fundsProgress: 25,
	heatRate: 80,
	pmLevel: 20,
	itemStyle: {
		areaColor: "#70F9BE",
		shadowColor: "#70F9BE88",
		shadowBlur: 5,
		shadowOffsetX: 0,
		shadowOffsetY: 10
	},
	emphasis: {
		itemStyle: {
			areaColor: "#70F9BE",
			borderColor: "#70F9BE",
			borderWidth: 10
		}
	}
},
{
	name: "周至县",
	projectNum: 12,
	projectProgress: 30,
	fundsProgress: 25,
	heatRate: 80,
	pmLevel: 20,
	itemStyle: {
		areaColor: "#46ABEF",
		shadowColor: "#46ABEF88",
		shadowBlur: 5,
		shadowOffsetX: -5,
		shadowOffsetY: 10
	},
	emphasis: {
		itemStyle: {
			areaColor: "#46ABEF",
			borderColor: "#46ABEF",
			borderWidth: 10
		}
	}
}
]
var lineData = [{
	name: "新城区",
	value: [108.991703, 34.27192]
},
{
	name: "碑林区",
	value: [108.960756, 34.246832]
},
{
	name: "莲湖区",
	value: [108.933194, 34.2656]
},
]
var customData = [{
	name: "新城区",
	value: [108.991703, 34.27192]
},
{
	name: "碑林区",
	value: [108.960756, 34.246832]
},
{
	name: "莲湖区",
	value: [108.90115, 34.270687]
}
];
export default {
	name: "shanxiProvince",
	props: {
		personalStatistics: {
			type: Array,
			default: () => {
				return []
			}
		},
		typeOptions: {
			type: Array,
			default: () => {
				return []
			}
		},
	},
	watch: {
		personalStatistics: {
			handler(n, o) {
				this.drawMapLine()
			},
			deep: true // 深度监听父组件传过来对象变化
		}
	},
	data() {

		return {
			mapData: require('./xianmap.json'),
			maxNum: 1,
			id: 'xianmap'
		}
	},
	mounted() {

		this.drawMapLine()
	},
	methods: {
		drawMapLine() {
			const tooltipFormatter = function (params) {
				const data = params.data;
				let pmLevelDesc = "";
				if (data.pmLevel >= 0 && data.pmLevel <= 15) {
					pmLevelDesc = "优";
				}
				return `<div style="width:320px;padding:20px;color:#666;box-shadow:rgb(177, 169, 169) 0 0 26px 2px;">
      <span style="font-size: 16px;">${params.name}</span>
      <div style="display: flex;justify-content:flex-start;font-size: 14px;margin-top:20px">
        <div style="display: flex;width: 150px;">
          <div style="width:7px;height: 7px;background: #3397ff;border-radius: 50%;margin: 6px 6px 6px 0;"></div>
          <div>项目个数:</div>
          <div style="margin-left: 10px">${data.projectNum}</div>
        </div>
        <div style="display: flex">
          <div style="width:7px;height: 7px;background: #3397ff;border-radius: 50%;margin: 6px 6px 6px 0;"></div>
          <div>空气质量等级:</div>
          <div style="margin-left: 10px">${pmLevelDesc}(${data.pmLevel})</div>
        </div>
      </div>
      <div style="display: flex;justify-content:flex-start;font-size: 14px;margin-top:20px">
        <div style="display: flex;width: 150px;">
          <div style="width:7px;height: 7px;background: #3397ff;border-radius: 50%;margin: 6px 6px 6px 0;"></div>
          <div>项目进度:</div>
          <div style="margin-left: 10px">${data.projectProgress}%</div>
        </div>
        <div style="display: flex">
          <div style="width:7px;height: 7px;background: #3397ff;border-radius: 50%;margin: 6px 6px 6px 0;"></div>
          <div>资金进度:</div>
          <div style="margin-left: 10px">${data.fundsProgress}%</div>
        </div>
      </div>
      <div style="display: flex;justify-content:flex-start;font-size: 14px;margin-top:20px">
        <div style="display: flex">
          <div style="width:7px;height: 7px;background: #3397ff;border-radius: 50%;margin: 6px 6px 6px 0;"></div>
          <div>清洁取暖率:</div>
          <div style="margin-left: 10px">${data.heatRate}%</div>
        </div>
      </div>
    </div>`;
			}
			if (this.personalStatistics.length > 0) {
				this.maxNum = this.personalStatistics[0].value
			} else {
				this.maxNum = 1
			}
			let echarts = require('echarts/lib/echarts')
			const name = '西安市'
			console.log(createcharts, this.mapData)
			createcharts.registerMap(name, this.mapData)
			let mapOption = {
				tooltip: {
					trigger: "item",
					showDelay: 0,
					transitionDuration: 0.2,
					borderWidth: 0,
					padding: 0,
					backgroundColor: "#FFF",
					formatter: tooltipFormatter
				},
				visualMap: {
					show: false,
					right: 0,
					bottom: 0,
					min: 0,
					max: this.maxNum,
					calculable: true,
					inRange: {
						color: this.typeOptions
					}
				},
				geo: {
					map: 'map',
					aspectScale: 1.04, //长宽比
					zoom: 1.2,
					roam: false, //禁止缩放
				},
				series: [
					{
						type: "map",
						map: "西安市",
						roam: true, //是否缩放
						zoom: 1,
						left: "center",
						top: "middle",
						bottom: "auto",
						right: "auto",
						label: {
							show: true,
							formatter: ({
								name
							}) => {
								switch (name) {
									case "莲湖区":
										return "";
									case "碑林区":
										return "";
									case "新城区":
										return "";
									default:
										return name;
								}
							}
						},
						itemStyle: {
							areaColor: "rgba(21,53,104,0.23)", //地图没数据时候颜色
							borderWidth: 2,
							borderColor: "#d81e06", //地图边框颜色
							borderType: "solid",
							opacity: 1
						},
						emphasis: {
							label: {
								show: true,
								color: "#ffffff",
								fontSize: 12,
								fontWeight: "normal",
								fontStyle: "normal"
							},
							itemStyle: {
								areaColor: "#fff000",
								borderWidth: 1.6,
								borderColor: "#F5F5F5",
								borderType: "solid",
								opacity: 1
							}
						},
						data: this.getData()
					}
				]
			}
			var myChart = echarts.init(document.getElementById(this.id))
			myChart.setOption(mapOption);
			window.addEventListener('resize', function () { //执行
				myChart.resize();
			})
		},
		getData() {
			let arr = []
			mapJson.features.forEach((el, index) => {
				arr[index] = {
					adcode: el.properties.adcode,
					areaName: el.properties.name,
					name: el.properties.name,
				}
				countryData.forEach(country => {
					if (el.properties.name == country.name) {
						arr[index]['projectNum'] = country.projectNum
						arr[index]['projectProgress'] = country.projectProgress
						arr[index]['fundsProgress'] = country.fundsProgress
						arr[index]['heatRate'] = country.heatRate
						arr[index]['pmLevel'] = country.pmLevel
						arr[index]['itemStyle'] = country.itemStyle
						arr[index]['emphasis'] = country.emphasis
					}
				})
				lineData.forEach(line => {
					if (el.properties.name == line.name) {
						// arr[index]['value'] = [].concat(line.value, Math.round(Math.random() * 1000))
						arr[index]['value'] = [].concat(line.value)

					}
				})
				customData.forEach(custom => {
					if (el.properties.name == custom.name) {
						// arr[index]['value'] = [].concat(custom.value, Math.round(Math.random() * 1000))
						arr[index]['value'] = [].concat(custom.value)

					}
				})
			})
			return arr
		},
	}
}
</script>

<style lang="scss"></style>