<template>
	<div class="hello">
		<!-- 		<div style="width:300px;height: 100vh;" class="lefttip">
			<div class="tipsconton">
				<div>西安市</div>
				<div style="margin-top: 5px;">
					<span style="margin-right: 10px;">有桩:1000</span>
					<span>无桩:1000</span>
				</div>
				<div style="height:10px;"></div>
			</div>
		</div> -->
		<div id="container">
			<div style="width:240px;height: 58vh;" class="lefttip">
				<div class="tipsconton">
					<!-- <div>西安市</div> -->
					<div style="margin-top: 5px;margin-left: 5px;">
						<span style="width: 100px;">全 &ensp; 部:</span>
						<span style="margin-right: 10px;">有桩:1729</span>
						<span>无桩:3230</span>
					</div>
					<div style="height:10px;"></div>
				</div>
				<div class="tipsconton">
					<!-- <div>西安市</div> -->
					<div style="margin-top: 5px;margin-left: 5px;">
						<span style="width: 100px;">未央区:</span>
						<span style="margin-right: 10px;">有桩:442</span>
						<span>无桩:383</span>
					</div>
					<div style="height:10px;"></div>
				</div>
				<div class="tipsconton">
					<!-- <div>西安市</div> -->
					<div style="margin-top: 5px;margin-left: 5px;">
						<span style="width: 100px;">莲湖区:</span>
						<span style="margin-right: 10px;">有桩:196</span>
						<span>无桩:252</span>
					</div>
					<div style="height:10px;"></div>
				</div>
				<div class="tipsconton">
					<!-- <div>西安市</div> -->
					<div style="margin-top: 5px;margin-left: 5px;">
						<span style="width: 100px;">长安区:</span>
						<span style="margin-right: 10px;">有桩:159</span>
						<span>无桩:621</span>
					</div>
					<div style="height:10px;"></div>
				</div>
				<div class="tipsconton">
					<!-- <div>西安市</div> -->
					<div style="margin-top: 5px;margin-left: 5px;">
						<span style="width: 100px;">雁塔区:</span>
						<span style="margin-right: 10px;">有桩:422</span>
						<span>无桩:793</span>
					</div>
					<div style="height:10px;"></div>
				</div>
				<div class="tipsconton">
					<!-- <div>西安市</div> -->
					<div style="margin-top: 5px;margin-left: 5px;">
						<span style="width: 100px;">临潼区:</span>
						<span style="margin-right: 10px;">有桩:2</span>
						<span>无桩:2</span>
					</div>
					<div style="height:10px;"></div>
				</div>
				<div class="tipsconton">
					<!-- <div>西安市</div> -->
					<div style="margin-top: 5px;margin-left: 5px;">
						<span style="width: 100px;">新城区:</span>
						<span style="margin-right: 10px;">有桩:129</span>
						<span>无桩:151</span>
					</div>
					<div style="height:10px;"></div>
				</div>
				<div class="tipsconton">
					<!-- <div>西安市</div> -->
					<div style="margin-top: 5px;margin-left: 5px;">
						<span style="width: 100px;">灞桥区:</span>
						<span style="margin-right: 10px;">有桩:227</span>
						<span>无桩:799</span>
					</div>
					<div style="height:10px;"></div>
				</div>
				<div class="tipsconton">
					<!-- <div>西安市</div> -->
					<div style="margin-top: 5px;margin-left: 5px;">
						<span style="width: 100px;">碑林区:</span>
						<span style="margin-right: 10px;">有桩:139</span>
						<span>无桩:176</span>
					</div>
					<div style="height:10px;"></div>
				</div>
				<div class="tipsconton">
					<!-- <div>西安市</div> -->
					<div style="margin-top: 5px;margin-left: 5px;">
						<span style="width: 100px;">蓝田县:</span>
						<span style="margin-right: 10px;">有桩:0</span>
						<span>无桩:1</span>
					</div>
					<div style="height:10px;"></div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import AMapLoader from "@amap/amap-jsapi-loader";
	import axios from 'axios';
	import {
		color
	} from "echarts/lib/export";
	import * as XLSX from 'xlsx';
	import icon1 from '../assets/blueicon.png'
	import icon2 from '../assets/redicon.png'
	// import arr from './map.js';
	// import axios from "axios";
	// import Qs from "qs";
	// console.log(arr)
	let arr = []
	export default {
		data() {
			return {
				map: null,
				marker: [],
				arr: [],
				visibleMarkers: [],
				district: null,
				districtCode: '610100',
				opts: {
					subdistrict: 0, //获取边界不需要返回下级行政区
					extensions: 'all', //返回行政区边界坐标组等具体信息
					level: 'district' //查询行政级别为 区
				}
			};
		},
		mounted() {
			this.readFile()

		},
		methods: {

			async readFile() {
				try {
					// 请求静态资源文件
					const response = await axios.get('/assets/data.xlsx', {
						responseType: 'arraybuffer'
					});
					const data = new Uint8Array(response.data);
					const workbook = XLSX.read(data, {
						type: 'array'
					});

					// 获取第一个有桩经纬度
					const worksheetName = workbook.SheetNames[0];
					const worksheetName2 = workbook.SheetNames[1];
					const worksheet = workbook.Sheets[worksheetName];
					const worksheet2 = workbook.Sheets[worksheetName2];
					// 将工作表转换为JSON
					const jsonData = XLSX.utils.sheet_to_json(worksheet);
					const jsonData2 = XLSX.utils.sheet_to_json(worksheet2);

					console.log(jsonData); // 打印解析后的数据
					var maparr = []
					jsonData.map((item) => {
						if (item['坐标'] && item['__EMPTY_1'] && item['名称']) {
							maparr.push({
								name: '有桩-' + item['名称'] + '-锁止器数' + item['锁止器数'],
								ip1: item['坐标'],
								ip2: item['__EMPTY_1'],
								icon: icon2,
								type: 1,
							})
						}
					})
					jsonData2.map((item) => {
						if (item['坐标'] && item['__EMPTY_1'] && item['名称']) {
							maparr.push({
								name: '无桩-' + item['名称'],
								ip1: item['坐标'],
								ip2: item['__EMPTY_1'],
								icon: icon1,
								type: 2,
							})
						}
					})
					console.log(maparr)
					this.arr = maparr
					this.initMap()
				} catch (error) {
					console.error('读取文件时发生错误:', error);
				}
			},
			initMap() {
				var _this = this;
				console.log(window)

				AMapLoader.load({
						key: "c8fe35c2371155bfbd7a6070b6f5f28d", // 申请好的Web端开发者Key，首次调用 load 时必填
						version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
						plugins: [
							"AMap.Map",
							"AMap.Map3D",
							"AMap.ControlBar",
							"AMap.DistrictSearch",
							"AMap.MarkerClusterer",
						], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
						AMapUI: {
							// 是否加载 AMapUI，缺省不加载
							version: "1.1", // AMapUI 缺省 1.1
							plugins: ["overlay/SimpleMarker"] // 需要加载的 AMapUI ui插件
						},
						Loca: {
							// 是否加载 Loca， 缺省不加载
							version: "2.0.0" // Loca 版本，缺省 1.3.2
						}
					})
					.then((AMap) => {
						this.map = new AMap.Map("container", {
							//设置地图容器id
							viewMode: "2D", //是否为3D地图模式
							zoom: 11, //初始化地图级别
							center: [108.844696, 34.224675, 25], //初始化地图中心点位置
						});
						// this.drawBounds()
						var citys = [{
								name: '莲湖区',
								color: '#80d8ff'
							},
							{
								name: '未央区',
								color: 'red'
							},
							{
								name: '碑林区',
								color: '#ffde37'
							},
							{
								name: '新城区',
								color: '#2747ff'
							},
							{
								name: '雁塔区',
								color: '#2747ff'
							},
							{
								name: '灞桥区',
								color: '#ffde37'
							},
							{
								name: '长安区',
								color: '#ffde37'
							},
							{
								name: '临潼区',
								color: 'red'
							},
							{
								name: '高陵区',
								color: '#80d8ff'
							},
							{
								name: '鄠邑区',
								color: '#80d8ff'
							},
							{
								name: '周至县',
								color: 'red'
							},
							{
								name: '蓝田县',
								color: '#2747ff'
							}
						]
						citys.map(item => {

							this.areaBG(this.map, item.name, item.color)
						})

						// 正常标记版本
						// for (var i = 0; i < _this.arr.length; i++) {
						// 	var content =
						// 		`<div class='marker-wrap'><img style='width:20px;height:20px;' src='${_this.arr[i].icon}' /><div style="min-width:120px;font-size:10px;color:#d81e06;font-weight:600;" class='marker-txt'>${_this.arr[i].name}</div></div>`; //自定义覆盖物内容
						// 	var markerItem = new AMap.Marker({
						// 		position: [_this.arr[i].ip1, _this.arr[i].ip2], // 经纬度
						// 		offset: new AMap.Pixel(-13, -20), //覆盖物偏移量
						// 		content: content, //自定义内容
						// 	});
						// 	markerItem.on("click", _this.markerClick); //为覆盖物增加点击事件
						// 	markerItem.setExtData({
						// 		deviceMarkId: _this.arr[i]
						// 	}); //为覆盖物点击事件增加自定义参数
						// 	_this.marker.push(markerItem); //在地图上渲染覆盖物
						// }

						// this.map.add(this.marker);

						// 海量点版本

						var maparrs = []
						_this.arr.map((item, index) => {

							maparrs.push({
								lnglat: [item.ip1, item.ip2],
								name: item.name,
								style: index,
								type: item.type
							})
						})
						// 有桩和无桩样式区分
						var stylearr = []
						maparrs.map((item, index) => {
							// console.log(item)
							stylearr.push({
								url: item.type == 1 ? icon2 : icon1,
								anchor: [25, 50],
								size: [20, 20],
								zIndex: 1,
							})
						})
						// console.log(stylearr)
						this.initMassMarks(maparrs, stylearr, [10, 20])
					})
					.catch((e) => {
						console.log(e);
					});

			},
			//初始化海量点对象
			initMassMarks(points, style, zooms) {
				var _this = this
				var mass = new AMap.MassMarks(points, {
					opacity: 1,
					style: style,
					zooms: zooms
				})
				mass.on('click', function(e) {
					_this.showWindow(e.data); //点击海量点展示数据窗口，代码省略。
				})
				mass.setMap(this.map);
			},
			showWindow(val) {
				console.log(val)
				alert(val.name)
			},
			markerClick(e) {
				var demo = e.target.getExtData();
				console.log("demo: ", demo);
				console.log("demo: ", demo.deviceMarkId.name);
				alert(demo.deviceMarkId.name)
			},
			addpolyline(AMap) {
				var maparr = []
				arr.map((item) => {
					// console.log(item)
					maparr.push([item.ip1, item.ip2])
				})
				// console.log(maparr)
				var polyline = new AMap.Polyline({
					path: maparr,
					strokeColor: "#3366FF",
					strokeWeight: 4,
					strokeOpacity: 1,
					strokeStyle: "solid",
					strokeDasharray: [10, 5],
					lineJoin: "round"
				});
				polyline.setMap(this.map)
			},
			areaBG(map, cityName, color) {
				// AMapLoader.service('AMap.DistrictSearch', function() {
				let opts = {
					subdistrict: 1, // 返回下一级行政区
					extensions: 'all', // 返回行政区边界坐标组等具体信息
					level: 'district' // 查询行政级别为市
				};
				// 实例化DistrictSearch
				let district = new AMap.DistrictSearch(opts);
				district.setLevel('district');
				// 行政区查询
				console.log('aaaaaa', district)
				district.search(`${cityName}`, function(status, result) {
					console.log('aaaaaxxxxa', cityName, result)
					// 获取边界信息
					result.districtList.map(item => {
						let bounds = item.boundaries;
						let polygons = [];
						if (bounds) {
							for (let i = 0, l = bounds.length; i < l; i++) {
								// 生成行政区划polygon
								let polygon = new AMap.Polygon({
									map: map,
									strokeWeight: 1,
									path: bounds[i],
									fillOpacity: 0.2,
									fillColor: color,
									strokeColor: color
								});
								polygons.push(polygon);
							}
						}
					})

				});
				// });
			},
			drawBounds() {

				let that = this
				this.district = new AMap.DistrictSearch(this.opts)
				console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', this.district)
				this.district.search('周至县', function(status, result) {
					console.log('aaa', status, result)
					that.map.remove(that.polygons) //清除上次结果
					that.polygons = [];

					let bounds = result.districtList[0].boundaries;
					if (bounds) {
						for (let i = 0, l = bounds.length; i < l; i++) {
							//生成行政区划polygon
							let polygon = new AMap.Polygon({
								strokeWeight: 1,
								path: bounds[i],
								fillOpacity: 0.4,
								fillColor: i > 3 ? '#80d8ff' : '#ffec19',
								strokeColor: i > 3 ? '#80d8ff' : '#ffec19'
							});
							that.polygons.push(polygon);
						}
					}
					that.map.add(that.polygons)
					that.map.setFitView(that.polygons); //视口自适应
				});
			}

		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.hello {
		display: flex;
	}

	#container {
		padding: 0px;
		margin: 0px;
		width: 100%;
		height: 100vh;
	}

	.marker-txt {
		font-size: 14px;
	}

	.lefttip {
		background: #2c343c;
		color: #fff;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 999;
		opacity: 0.5;
		text-align: left;
	}

	.tipsconton {
		margin: 10px 0 10px 0px;
		border-bottom: 1px solid #fff;
	}
</style>