<template>
	<!-- <img alt="Vue logo" src="./assets/logo.png"> -->
	<HelloWorld msg="Welcome to Your Vue.js App" />
	<!-- <map2 msg="Welcome to Your Vue.js App" /> -->
</template>

<script>
	import HelloWorld from './components/HelloWorld.vue'
	import map2 from './components/map2.vue'

	export default {
		name: 'App',
		components: {
			HelloWorld,
			map2
		},
		created() {
			document.title = '公共自行车站点分布';
		}
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		/* margin-top: 20px; */
		margin: 0;
		padding: 0;
	}

	html,
	body {
		margin: 0;
		padding: 0;
	}
</style>